.App {
  text-align: center;
}

/* General styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
}

h1, h2, h3, h4 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

/* Navigation styling */
nav {
  margin: 20px 0;
}

nav a {
  display: inline-block;
  margin: 0 10px;
  padding: 8px 16px;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

nav a:hover {
  background-color: #e0e0e0;
}

/* Didask specific styles */
.bible-quote {
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 0;
  border-left: 4px solid #2196f3;
  font-style: italic;
}

.listening-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #f44336;
  border-radius: 50%;
  margin-right: 8px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}