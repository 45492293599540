body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    background-color: #f8f9fa;
    color: #333;
}

#hill-chart-container {
    display: inline-block;
    position: relative;
    padding: 15px;
    background: white;
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    margin: 15px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    max-width: 100%;
    overflow: hidden;
    touch-action: none;
}

#hill-chart {
    background-color: #f8f9fa;
    border-radius: 4px;
    border: 1px solid #e1e4e8;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#hill-path {
    stroke: #4a86e8;
    stroke-width: 3;
    stroke-linecap: round;
}

h1 {
    color: #333;
    font-size: 28px;
    margin-bottom: 24px;
    font-weight: 500;
}

.dot-label {
    font-size: 12px;
    font-weight: 500;
    pointer-events: none;
    fill: #333;
}

/* Retain a subtle animation for the lines but make it less distracting */
@keyframes subtle-color-shift {
    0% {stroke-opacity: 0.85;}
    50% {stroke-opacity: 1;}
    100% {stroke-opacity: 0.85;}
}

.rainbow-line {
    animation: subtle-color-shift 3s ease-in-out infinite;
}

/* Tone down blinking effect */
@keyframes subtle-blink {
    0% {opacity: 0.9;}
    50% {opacity: 0.7;}
    100% {opacity: 0.9;}
}

.blink-text {
    animation: subtle-blink 2s ease-in-out infinite;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    #hill-chart-container {
        padding: 10px;
    }
    
    .dot-label {
        font-size: 11px;
    }
    
    h1 {
        font-size: 24px;
    }
}

.dev-notice {
    background: #f5f5f5 !important;
    border: 1px solid #e1e4e8 !important;
    color: #555 !important;
    font-weight: 500 !important;
    padding: 8px !important;
    margin-bottom: 16px !important;
    border-radius: 4px !important;
}